import Typewriter from 'typewriter-effect';

function Jannah() {
return(<Typewriter
  options={{
    strings: [
    "Hi Monkey, this is all to prove that you are absolutely not sweeter than me (U+1F602)	",
    "So as a quick one, I'll be sharing 10 things I love about you, in no particular order"],
    autoStart: true,
    loop: true,
    delay: 25,
    deleteSpeed: 25}}
    />)
}

export default Jannah;

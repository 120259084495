import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Jannah from "./pages/Jannah";
import Cv from "./pages/Cv";


function App() {
 return(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
  <Routes>
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="jannah" element={<Jannah />} />
      <Route path="cv" element={<Cv />} />
    </Route>
  </Routes>
</BrowserRouter>

 )

}

export default App;

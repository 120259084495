import Typewriter from 'typewriter-effect';

function Home() {
return(<Typewriter
  options={{
    strings: [
    "Hello, my name's Etienne, I'm a software engineer amongst other things and welcome to eteyen.co 😃",
    "A quick about me: I love bringing ideas to life, building community, physical and mental health coaching, competing in sports, learning languages, making music, dancing and spending time with friends, family and my wonderful girlfriend.",
    "This will be a beautiful, personal site in the future but for now, pls enjoy these recurring messages :)",
    "Free Congo, Free Sudan, Free Palestine"],
    autoStart: true,
    loop: true,
    delay: 25,
    deleteSpeed: 25}}
    />)
}

export default Home;
